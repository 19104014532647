/* eslint-disable global-require */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { FaArrowRight } from "react-icons/fa";

const Excerpt = ({ post }) => {
  const excerptLength = 350;
  const link = post.title.replace(/ /g, "-").replace("?", "").toLowerCase();
  return (
    <Link to={`/blog/${link}`} key={post.title}>
      <div
        key={post.title}
        className="border p-3 my-6 bg-gray-100 hover:bg-gray-200 rounded"
      >
        <p className="text-2xl">{post.title}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: `${post.content.slice(0, excerptLength)}...`,
          }}
        />
        <div className="text-darkred font-bold flex items-center pt-2">
          Read More <FaArrowRight className="ml-1" />
        </div>
      </div>
    </Link>
  );
};

Excerpt.propTypes = {
  post: PropTypes.object.isRequired,
};

export default Excerpt;
