/* eslint-disable global-require */
import React from "react";
import PropTypes from "prop-types";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const Pagination = ({
  hasOlderPosts,
  hasNewerPosts,
  goToOlderPages,
  goToNewerPages,
}) => {
  return (
    <div className="flex items-between">
      {hasOlderPosts && (
        <div
          className="text-xl text-darkred hover:text-charcoal border-b cursor-pointer"
          onClick={goToOlderPages}
        >
          <div className="flex items-center">
            <FaArrowLeft className="mr-2" /> Older Posts
          </div>
        </div>
      )}
      {hasNewerPosts && (
        <div
          className="ml-auto text-xl text-darkred hover:text-charcoal border-b cursor-pointer"
          onClick={goToNewerPages}
        >
          <div className="flex items-center">
            Newer Posts <FaArrowRight className="ml-2" />
          </div>
        </div>
      )}
    </div>
  );
};

Pagination.propTypes = {
  hasOlderPosts: PropTypes.bool.isRequired,
  hasNewerPosts: PropTypes.bool.isRequired,
  goToOlderPages: PropTypes.func.isRequired,
  goToNewerPages: PropTypes.func.isRequired,
};

export default Pagination;
