import React from "react";
import PropTypes from "prop-types";

const Tags = ({ blogTags, currentTag, setTag }) => {
  const tags = [];

  blogTags.forEach((postTag) => {
    if (postTag) {
      tags.push(
        <div
          key={postTag}
          className={`${
            currentTag === postTag
              ? "bg-charcoal hover:bg-gray-900 text-white"
              : "bg-gray-100 hover:bg-gray-200"
          } inline-block p-2 border mr-3  cursor-pointer rounded`}
          onClick={() =>
            setTag((prevState) => (prevState === postTag ? null : postTag))
          }
        >
          {postTag}
        </div>
      );
    }
  });

  if (tags.length > 0) {
    return (
      <div className="flex-grow ml-10 hidden md:block">
        <h1 className="text-4xl py-4 border-b">Tags</h1>
        <div className="py-6">{tags}</div>
      </div>
    );
  }
  return null;
};

Tags.propTypes = {
  blogTags: PropTypes.array.isRequired,
  setTag: PropTypes.func.isRequired,
  currentTag: PropTypes.string,
};

export default Tags;
