/* eslint-disable global-require */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Tags from "../components/blog/tags";
import Pagination from "../components/blog/pagination";
import Excerpt from "../components/blog/excerpt";

export const query = graphql`
  query getBlogPosts {
    allBlogJson(
      filter: { publish: { eq: true } }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        content
        title
        tags
      }
      distinct(field: tags)
    }
  }
`;

const Blog = ({ data, location }) => {
  const [page, setPage] = useState(1);
  const [tag, setTag] = useState(location.state ? location.state.tag : null);
  const { nodes, distinct } = data.allBlogJson;
  const postsPerPage = 10;
  const posts = [];
  const filteredPosts = tag
    ? nodes.filter((post) => post.tags.includes(tag))
    : nodes;
  const currentPosts = filteredPosts.slice(
    (page - 1) * postsPerPage,
    page * postsPerPage
  );
  const numberOfPosts = filteredPosts.length;
  const hasOlderPosts = numberOfPosts / postsPerPage > page;
  const hasNewerPosts = page > 1;

  const goToOlderPages = () => {
    setPage((prevState) => prevState + 1);
  };

  const goToNewerPages = () => {
    setPage((prevState) => prevState - 1);
  };

  currentPosts.forEach((post) => {
    posts.push(<Excerpt post={post} key={post.title} />);
  });

  return (
    <Layout>
      <SEO title="Blog" />
      <div className="flex max-w-5xl m-auto px-3 py-6">
        <div className="max-w-md md:max-w-xl lg:max-w-2xl sm:min-w-88 m-auto">
          <h1 className="text-4xl py-4 border-b">
            {posts.length > 0
              ? "Latest Posts"
              : "No posts yet, check back soon!"}
          </h1>
          {posts}
          <Pagination
            goToOlderPages={goToOlderPages}
            goToNewerPages={goToNewerPages}
            hasOlderPosts={hasOlderPosts}
            hasNewerPosts={hasNewerPosts}
          />
        </div>
        <Tags currentTag={tag} setTag={setTag} blogTags={distinct} />
      </div>
    </Layout>
  );
};

Blog.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default Blog;
